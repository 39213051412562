<style lang="scss" scoped>
.dialog-header {

  .heading_text {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
  }

  .close-btn {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}
</style>

<template>
  <v-dialog v-model="dialog.flag" width="600px">
    <v-card>
      <div>

        <v-card-title class="pl-7 dialog-header">
          <span class="heading_text">{{ $lang.MANUAL_PAYMENT_ENTRY }}</span>
          <span class="close-btn">
              <v-icon class="float-right" color="#333333" @click="closeDialog">mdi-close</v-icon>
            </span>
        </v-card-title>
        <v-card-text class="pt-5 px-8">
          <v-row justify="start">
            <v-col md="4">
              <label class="text-label">{{ $lang.LOAN_ID }}</label>
              <p>#1234567</p>

            </v-col>
            <v-col class="text-center " md="4">
              <label class="text-label">{{ $lang.EMI_ID }}</label>
              <p>#1234567</p>
            </v-col>
            <v-col class="text-right py-0" md="4">
              <label class="text-label">{{ $lang.TOTAL_PAYABLE }}</label>
              <p>#1234567</p>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col class="py-0" md="6">
              <v-text-field
                  :label="$lang.PAYMENT_DATE"
                  maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" md="6">
              <v-text-field
                  :label="$lang.PAYMENT_ID"
                  maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" md="6">
              <v-text-field
                  :label="$lang.ENTER_AMOUNT_RECEIVED"
                  maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" md="6">
              <v-text-field
                  :label="$lang.PAYMENT_MODE"
                  maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" md="12">
              <v-text-field
                  :label="$lang.REMARK"
                  maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
          <v-btn class="px-5 py-4 " color="secondary_2"  rounded type="submit" height="40px">
            <b style="color: #FFFFFF"> {{ $lang.RECEIVED }}</b>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>

  </v-dialog>
</template>
<script>
export default {
  props: ['dialog'],
  components: {},
  data: () => ({
    comment: '',
  }),
  created() {
    // this.initialize()
  },

  methods: {
    closeDialog() {
      this.dialog.flag = false
    },
    /* function to fetch data from api */
    addComment(id) {
      const self = this;
      self.id = id
      self.loading = true;
      let params = {
        "id": id
      };
      const successHandler = (response) => {
        if (response.data.success) {
          self.form = response.data.user_data
        }
      };
      const finallyHandler = () => {
        self.table_loading = false;
      };
      self.request_GET(self, self.$urls.TEAMMATE_DETAILS, params, successHandler, null,
          null, finallyHandler);
    },
    /* reset the form */
    resetForm() {
      this.$refs.add_member_form.reset()
    },
    /* function for save the member data */

  },
}
</script>
